<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EDIT DATA</h4></v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12 v-if="type==='Car'">
            <v-text-field
              class="mx-2"
              v-model="auto_mv_file_no"
              label="MV File #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_plate_no"
              label="Plate #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_unit_color"
              label="Unit Color"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_chasis_no"
              label="Chasis #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_engine_no"
              label="Engine #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="type==='Equipment'">
            <v-text-field
              class="mx-2"
              v-model="equipment_serial_no"
              label="Serial #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="equipment_model_color"
              label="Description"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="equipment_date_procure"
              label="Date Procure"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="equipment_unit_price"
              label="Unit Price"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="equipment_unit_quantity"
              label="Unit Quantity"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="type==='LandAndBuilding'">
            <v-text-field
              class="mx-2"
              v-model="land_title_no"
              label="Land Title #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field

              class="mx-2"
              v-model="land_area"
              label="Land Area"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field

              class="mx-2"
              v-model="location"
              label="Location"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field

              class="mx-2"
              v-model="land_procure"
              label="Land Procure"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field

              class="mx-2"
              v-model="land_value"
              label="Land Value"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
            <v-select
              outlined
              class="mx-2"
              v-model="type_of_property"
              dense
              label="Type of Property"
              :items="['AGRICULTURAL','COMMERCIAL','RESIDENTIAL']"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-text-field

              class="mx-2"
              v-model="pre_owner"
              label="Prev. Owner"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="sell_to"
              label="Sell to"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="done_request" v-if="!saving_request">
              Done
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import {mdiAlertOutline} from '@mdi/js'
import {mapActions, mapGetters} from 'vuex'

const initialState = () => {
  return {
    saving_request: false,
    alert: false,
    alert_message: '',

    auto_mv_file_no: '',
    auto_plate_no: '',
    auto_unit_color: '',
    auto_chasis_no: '',
    auto_engine_no: '',

    equipment_serial_no: '',
    equipment_model_color: '',
    equipment_date_procure: '',
    equipment_unit_price: '0',
    equipment_unit_quantity: '0',


    pre_owner: '',
    land_value: '0',
    land_procure: '',
    location: '',
    land_area: '',
    land_title_no: '',
    sell_to: '',
    type_of_property: '',
  }
}
export default {
  props: {
    details: Object,
    type: String,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['employee_id']),
  },
  methods: {
    ...mapActions('inventory_history', ['register_inventory_history']),
    ...mapActions('branch_information', ['branch_data']),
    ...mapActions('category', ['list_of_all_categories']),
    ...mapActions('inventory', ['update_inventory_basic_info']),
    initialize_data() {
      if (this.type === 'Car') {
        this.auto_mv_file_no = this.details.auto_mv_file_no
        this.auto_plate_no = this.details.auto_plate_no
        this.auto_unit_color = this.details.auto_unit_color
        this.auto_chasis_no = this.details.auto_chasis_no
        this.auto_engine_no = this.details.auto_engine_no
      } else if (this.type === 'Equipment') {
        this.equipment_serial_no = this.details.equipment_serial_no
        this.equipment_model_color = this.details.equipment_model_color
        this.equipment_date_procure = this.details.equipment_date_procure
        this.equipment_unit_price = this.details.equipment_unit_price
        this.equipment_unit_quantity = this.details.equipment_unit_quantity
      } else if (this.type === 'LandAndBuilding') {
        this.pre_owner = this.details.pre_owner
        this.land_value = this.details.land_value
        this.land_procure = this.details.land_procure
        this.location = this.details.location
        this.land_area = this.details.land_area
        this.land_title_no = this.details.land_title_no
        this.sell_to = this.details.sell_to
        this.type_of_property = this.details.type_of_property
      }
    },
    async selected_category() {
      this.is_forbidden = true
      if (this.category_id === 4 || this.category_id === 3) {
        this.is_forbidden = false
      } else {
        this.selection = ''
      }
      this.branch_id = ''
      await this.branch_data({
        category_id: this.category_id,
        is_region: this.selection === 'Regional' ? 1 : 0,
        is_territory: this.selection === 'Territory' ? 1 : 0,
      })
        .then(response => {
          this.branch_items = response.data[0].active
        })
        .catch(error => {
          console.log(error)
        })
    },
    done_request() {
      this.saving_request = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('id', this.details.id)
        data.append('type', this.type)
        if (this.type === 'Car') {
          data.append('auto_mv_file_no', this.auto_mv_file_no.toUpperCase())
          data.append('auto_plate_no', this.auto_plate_no.toUpperCase())
          data.append('auto_unit_color', this.auto_unit_color.toUpperCase())
          data.append('auto_chasis_no', this.auto_chasis_no.toUpperCase())
          data.append('auto_engine_no', this.auto_engine_no.toUpperCase())
        } else if (this.type === 'Equipment') {
          data.append('equipment_serial_no', this.equipment_serial_no.toUpperCase())
          data.append('equipment_model_color', this.equipment_model_color.toUpperCase())
          data.append('equipment_date_procure', this.equipment_date_procure)
          data.append('equipment_unit_price', this.equipment_unit_price)
          data.append('equipment_unit_quantity', this.equipment_unit_quantity)
        } else if (this.type === 'LandAndBuilding') {
          data.append('land_title_no', this.land_title_no.toUpperCase());
          data.append('land_area', this.land_area.toUpperCase());
          data.append('location', this.location.toUpperCase());
          data.append('land_procure', this.land_procure.toUpperCase());
          data.append('land_value', this.land_value);
          data.append('type_of_property', this.type_of_property.toUpperCase());
          data.append('pre_owner', this.pre_owner.toUpperCase());
          data.append('sell_to', this.sell_to.toUpperCase());
        }
        this.update_inventory_basic_info(data)
          .then(response => {
            this.$emit('response', response)
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_request = false
      }
    },
  },
}
</script>
